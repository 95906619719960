.sidebar-container {
    position: relative;
    z-index: 9999;
}

/* Fixed position and width for sidebar with hidden scrollbar */
.sidebar {
    position: fixed;
    top: 0;
    left: 0; /* Ensure sidebar starts from the left */
    height: 100vh; /* Full viewport height */
    width: 270px; 
    background-color: #f8f9fa;
    color: #000;
    transition: width 0.3s, left 0.3s;
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.sidebar::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

/* Sidebar hidden when closed */
.sidebar.closed {
    width: 0;
    overflow: hidden;
}

/* Sidebar toggle button styling */
.sidebar-toggle {
    background: #097dc4;
    border: none;
    color: white;
    font-size: 20px;
    padding: 7px;
    cursor: pointer;
    transition: left 0.3s ease, top 0.3s ease;
}

.sidebar-toggle-closed {
    position: fixed;
    left: 1px;
    top: 0px;
    background: #097dc4;
    border: none;
    color: white;
    font-size: 20px;
    padding: 7px;
    cursor: pointer;
    z-index: 1100;
    transition: left 0.3s ease, top 0.3s ease;
}

/* Adjust positions based on sidebar state */
.sidebar-open {
    left: calc(257px - 10px);
    top: 0;
}

@media (max-width: 1250px) {
    .sidebar-open {
        left: 183px;
        top: 0;
    }
}

@media (max-width: 1000px) {
    .sidebar-open {
        left: 160px;
        top: 0;
    }
}

@media (max-width: 576px) {
    .sidebar-open {
        left: 160px;
        top: 0;
    }
}

/* Styling for the sidebar menu */
.sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Styling for sidebar links */
.sidebar-link {
    display: block;
    padding: 15px;
    font-size: 14px;
    border-radius: 5px;
    text-decoration: none;
    color: #000;
    transition: background-color 0.3s;
}

/* Styling for selected link */
.sidebar-selected {
    display: block;
    padding: 15px;
    font-size: 14px;
    border-radius: 5px;
    text-decoration: none;
    background-color: #097dc4;
    color: #fff;
    transition: background-color 0.3s;
}

/* Hover effect for links */
.sidebar-link:hover, .sidebar-selected:hover {
    background-color: rgba(86, 164, 241, 0.7);
    color: white;
}

.sidebar-link i {
    margin-right: 10px;
}

/* Adjustments for different screen sizes */
@media (max-width: 480px) {
    .sidebar {
        width: 200px ; /* Adjusted width for extra small screens */
    }
    .sidebar.open + .sidebar-toggle {
        left: 160px; /* Adjusted toggle position for extra small screens */
   
    }
}

@media screen and (max-width: 768px) {
    .sidebar {
        width: 200px; /* Adjusted width for small screens */
    }
    .sidebar.open + .sidebar-toggle {   
        left: 160px; /* Adjusted toggle position for small screens */

    }
    
}

@media (max-width: 1225px) {
    .sidebar {
        width: 200px; /* Adjusted width for large screens */
    }
    .sidebar.open + .sidebar-toggle {
        left: 214px; /* Adjusted toggle position for large screens */
    }
}

@media (min-width: 1225px) {
    .sidebar {
        width: 250px; /* Default width for extra-large screens */
    }
    .sidebar.open + .sidebar-toggle {
        left: 237px; /* Default toggle position for extra-large screens */
    }
}
