.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(5px); 
}

.custom-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 90%;
    max-width: 500px;
    border-radius: 1cqh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center !important;

}

.custom-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    font-size: 40;
    color: red;
}
