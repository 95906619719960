/* aboutus.css */

.about-us-container {
  height: 1vh;
}

.inner-page{
padding-bottom: 70px !important;

}

.about-us-card {
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(166, 221, 255, 1.7);
}

.about-us-card-body {
  padding: 45px;
  height: auto;
}

.about-us-heading {
  margin-bottom: 2px;
  font-weight: bold;
  justify-self: start;
  justify-content: left;
text-align: left;
}

.about-us-text {
  font-size: 1rem;
  text-align: justify;
  line-height: 1.6;
}

.about-us-image {
  margin-top: 40px;
  border-radius: 20px;
  width: 80%;
  height: 400px;
  aspect-ratio: 1;
}







/* Media queries for responsiveness */
@media (max-width: 768px) {
 
 
.about-us-container-new {
  padding: 0;
  margin: 0;
}

.home-header-about-us{
  padding: 30px;
}
.about-us-col{
margin-top: 120px;
}

.about-us-heading {
  margin-top: 10px;
  padding-left: 27px;
  padding-right: 27px;

  font-weight: bold;
  justify-self: start;
  justify-content: left;
text-align: left;
}

  .about-us-text {
    padding-left: 27px;
    padding-right: 27px;
 
    font-size: 1.7vw;
    line-height: 1.4;
    
  }

  .about-us-image-mbl{
    width: 90%;
    height: 250px;
  }
}

@media (max-width: 576px) {
 
  .about-us-card-body {
    padding: 20px;
 
  }
 
.about-us-heading {
  margin-top: 20px;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  justify-self: start;
  justify-content: left;
text-align: left;
}
 

.about-us-image-mbl{
  width: 100%;
  height: 200px;
}
 
  .about-us-card-body {
    padding: 25px;
  }

  .about-us-text {
    font-size: 3vw;
    line-height: 1.2;
    padding: 0;
  }

  .about-us-image {
    width: 100%;
  }
}
