.page_404 {
    padding: 20px 0;
    background: #fff;
  }
  
  .page_404 img {
    width: 100%;
  }
  
  .four_zero_four_bg {
    background-image: url('https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif');
    height: 520px;
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
    margin: 0 auto;
  }
  
  .four_zero_four_bg h1 {
    font-size: 80px;
  }
  
  .four_zero_four_bg h3 {
    font-size: 80px;
  }
  
  .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #007bff;
    border-radius: 10px;
    margin: 20px 0;
    display: inline-block;
    
  }
  .link_404:hover {
    background: #67aaf1
  }
  
  .contant_box_404 {
    margin-top: -50px;
  }


@media (max-width: 768px) {
  .four_zero_four_bg {
    margin-top: 20px;
    height: 520px;
    width: 100%;
  }

  .four_zero_four_bg h1,
  .four_zero_four_bg h3 {
    font-size: 40px;
  }

  .contant_box_404 {
    margin-top: -30px;
  }
}

@media (max-width: 576px) {
  .four_zero_four_bg {
    height: 520px;
    width: 100%;
  }

  .four_zero_four_bg h1,
  .four_zero_four_bg h3 {
    font-size: 30px;
  }

  .contant_box_404 {
    margin-top: -20px;
    text-align: center;
  }

  .link_404 {
    padding: 8px 15px;
    font-size: 14px;
  }
}