

.transparent-background {
    background-color: rgba(255, 255, 255, 0.8); 
    border-radius: 10px; 
    padding: 20px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    color: #333; 
}

.video-details p {
    font-family: 'Arial', sans-serif; 
    font-size: 16px;
    margin-bottom: 10px; 
    color: #444; }

.video-details p strong {
    font-weight: bold;
    color: #000; 
}

.title-style {
    font-family: 'Verdana', sans-serif; 
    font-size: 24px; 
    color: #007bff; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); 
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #000; 
    border-radius: 50%; }



.focus-glow:hover {
    box-shadow: 0 0 10px rgba(97, 13, 13, 0.5);
border: 2px solid rgba(0, 0, 0, 0.5);
}



.youtube-video{
    width: 70%;
    height: 415px;
}
.youtube-thumbnail{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
}

.recent-lucky-draw-section{
    padding: 40px;
}

@media screen and (max-width: 768px) {
 
.recent-lucky-draw-section{
    padding: 10px;
}
 
 
    .youtube-video {
        width: 100%;
        height: 250px;
    
    }
    .youtube-thumbnail{
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 20px;
    }

}