/* Add a blur effect to the background when the modal is open */
.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-open .container-fluid {
    filter: blur(5px);
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
}


.Error-modal{
  background: linear-gradient(#3789ff, #7daaec);
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  max-width: 550px;
  height: auto;
  width: 100%;
  position: relative;
}
/* 
.coupon-card {
  background: #fff;
  border: 1px solid transparent;
  color: #0000;
  text-align: center;
  border-image: url(../images/OfferBorder.png) 250 round;
  border-width: 30px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  max-width: 550px;
  height: auto;
  width: 100%;
  position: relative;
}


@media (max-width: 768px) {
  .coupon-card {
      padding: 20px 20px;
      max-width: 100%;
      margin: 0;
      border-radius: 0;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
} */
.coupon-card {
  background: #fff;
  border: 1px solid transparent;
  color: #000; /* Fixed typo from '#0000' */
  text-align: center;
  border-image: url(../images/OfferBorder.png) 250 round;
  border-width: 30px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  max-width: 550px; /* Maintain width for larger screens */
  height: auto;
  width: 100%; /* Adjust to container or screen */
  position: relative;
  border-radius: 15px; /* Added for smoother edges */
  overflow: hidden; /* Prevent content overflow */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px; /* Add consistent padding */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


/* For medium-sized screens (tablets and small desktops) */
@media (min-width: 710px) and (max-width: 1024px) {
  .coupon-card {
    padding: 25px; /* Slightly more padding for larger screens */
    max-width: 80%; /* Make the modal slightly larger */
    margin: 0 auto;
    border-radius: 12px; /* Rounded corners but less than smaller screens */
    border-width: 25px; /* Adjust border size */
  }

  .modal-overlay {
    padding: 35px; /* Add extra padding for spacing */
  }
}

/* For smaller devices like mobile phones */
@media (max-width: 768px) {
  .coupon-card {
    padding: 20px;
    max-width: 90%; /* Adjust modal size for smaller screens */
    margin: 0 auto; /* Center the card */
    border-radius: 10px; /* Slightly reduced for smaller devices */
    border-width: 20px; /* Adjust border size */
  }

  .modal-overlay {
    padding: 35px; /* Smaller padding for tighter screens */
  }
}

/* For extra-small screens (older smartphones) */
@media (max-width: 576px) {
  .coupon-card {
    padding: 15px;
    max-width: 95%; /* Further reduce size for extra small devices */
    margin: 0 auto;
    border-radius: 8px; /* Reduce radius further */
    border-width: 15px; /* Adjust border width for compact view */
  }

  .modal-overlay {
    padding: 10px; /* Tighter padding for extra small devices */
  }
}

.glow-card {
  
}
.close-btn {
  position: absolute;
  top: 5px;
  right: 3px;
  height: 22px;
  width : 22px;
  background: none;
  border: none;
  color: #fff;
  background-color: red;
  border-radius: 100%;
  font-size: 15px;
  cursor: pointer;
}

.logo {
  width: 120px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.coupon-row {
  display: flex;
  align-items: center;
  margin: 25px auto;
  width: fit-content;
}

#cpnCode {
  border: 1px dashed #fff;
  padding: 10px 20px;
  border-right: 0;
}

#cpnBtn {
  border: 1px solid #fff;
  background: #fff;
  padding: 10px 20px;
  color: #7158fe;
  cursor: pointer;
}

.circle1, .circle2 {
  background: #fffffff1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.circle1 {
  left: -25px;
}

.circle2 {
  right: -25px;
}


.translate-container {
    margin-right: 1rem;
  }
  
  .language-select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }


  #google_translate_element select option:first-child {
    display: none;
  }
  
  
  .language-select option {
    padding: 5px;
  }
  
  /* Highlighting the selected option */
  .language-select option[value="en"]:checked {
    font-weight: bold;
    color: #007bff; /* Highlight color for English */
  }
  
  .language-select option[value="ur"]:checked {
    font-weight: bold;
    color: #28a745; /* Highlight color for Urdu */
  }
  


  #google_translate_element {
    position: relative;
    display: inline-block;
  }
  
  .translate-select {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 10px 20px;
    border: 2px solid #007bff; /* Bootstrap primary color */
    border-radius: 4px;
    background-color: #fff;
    color: #007bff; /* Text color */
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2); /* Soft shadow */
  }
  
  .translate-select:hover {
    background-color: #007bff; /* Change background on hover */
    color: #fff; /* Change text color on hover */
  }
  
  .translate-select:focus {
    outline: none;
    border-color: #0056b3; /* Darker blue on focus */
  }
  
  .translate-options {
    display: none; /* Initially hidden */
    position: absolute;
    top: 100%; /* Position below the select */
    left: 0;
    right: 0;
    z-index: 1000;
    border: 2px solid #007bff;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    transition: all 0.3s ease;
  }
  
  .translate-options.active {
    display: block; /* Show options when active */
  }
  
  .translate-option {
    padding: 10px 15px;
    color: #333;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .translate-option:hover {
    background-color: #f1f1f1; /* Highlight on hover */
  }
  
  