@media screen and (max-width: 576px) {
    .faqs-new{
        padding: 10px;

    }
    .faq-acc{
        margin-top: 260px;
    }
    .faq-heading{
        margin-top: -70px;
    }
    
}