
  .inner-page{
  padding-bottom: 70px !important;
  
  }
  .privacy-policy {
    margin-top: 200px;
    margin: 0 auto;
  }
  
  .about-us-heading {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
    font-size: 1.5rem;
  }
  
  .about-us-text {
    font-size: 1rem;
    text-align: justify;
    line-height: 1.6;
  }
  
  .text-center {
    text-align: center;
  }
  
  .mt-5 {
    margin-top: 5rem; /* Adjust as needed */
  }

  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .about-us-heading {
      font-size: 1.5rem;
    }

    .privacy-mobile-view{
      margin-top: 150px;
    }
  
    .about-us-text {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .about-us-heading {
      font-size: 1.3rem;
    }
  
    .about-us-text {
      font-size: 0.8rem;
    }
  }
  