/* src/components/videos/EditVideo.css */
.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: #f8f9fa; /* Light background for better contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

h1 {
    margin-bottom: 20px;
    color: #343a40; /* Darker color for headings */
    text-align: center;
}

.form-control {
    border-radius: 8px; /* Rounded corners for inputs */
}

.form-label {
    font-weight: bold;
    color: #495057; /* Slightly darker color for labels */
}

.mb-3 {
    margin-bottom: 20px;
}

.img-thumbnail-preview {
    display: block;
    margin-top: 10px;
    max-width: 200px;
    border-radius: 8px; /* Match the rounded corners */
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
}

.btn {
    border-radius: 8px; /* Rounded corners for buttons */
}

.edit-video-card {
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.img-thumbnail-preview {
    margin-top: 10px;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .edit-video-card {
        padding: 20px;
    }

    .img-thumbnail-preview {
        margin-top: 5px;
    }
}

@media (max-width: 576px) {
    .edit-video-card {
        padding: 15px;
    }
}
